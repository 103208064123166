<template>
  <div class="order-form">
    <!-- 配送信息 -->
    <div class="delivery" v-if="enabled" @click="getWxAddr">
      <div class="van-cell van-cell--center van-cell--clickable van-cell--borderless van-contact-card van-contact-card--add" v-if="JSON.stringify(delivery) == '{}'">
        <van-icon name="location-o" class="left-icon" />
        <div class="van-cell__value van-cell__value--alone van-contact-card__value add-contacts">添加联系人</div>
        <van-icon name="arrow" class="right-icon" />
      </div>
      <div class="van-cell van-cell--center van-cell--clickable van-cell--borderless van-contact-card" v-else>
        <van-icon name="location-o" class="left-icon" />
        <div class="van-cell__value van-cell__value--alone van-contact-card__value">
          <div class="header"><span class="name">{{delivery.conName}}</span> <span class="tel">{{delivery.conMobile}}</span></div>
          <div class="address van-multi-ellipsis--l2">{{delivery.conAddress}}</div>
        </div>
        <van-icon name="arrow" class="right-icon" />
      </div>
    </div>
    <!-- 商品信息 -->
    <div class="goods">
      <header>

      </header>
      <div class="content">
        <van-card
          v-for="(one, index) in goodsList"
          :key="index"
          num="1"
          price="2.00"
          title="《西游记》孙教授批注版"
          thumb="https://img.yzcdn.cn/vant/ipad.jpeg"
        >
          <template #title>
            <van-tag  type="primary">{{one.packName}}</van-tag>
          </template>
          <template #price>
            <van-tag  plain type="danger">￥{{one.price}}</van-tag>
          </template>
          <template #thumb>
            <img :src="one.orderImg" style="width: 80px;height: 75px;"/>
          </template>
          <template  #num>
           <span>数量：{{one.amount}}</span>
          </template>
        </van-card>

        <div class="van-cell">
          <span class="label">配送费用</span>
          <span class="value">{{deliveryDesc}}</span>
        </div>
        <div class="van-cell">
          <span class="label">订单总金额</span>
          <span  class="value price">
            ￥{{origPrice}}
        </span>
        </div>

        <div class="van-cell" v-show="dedShow">
          <span class="label">优惠券抵扣</span>
          <span  class="value price">
            -￥{{dedPrice}}
        </span>
        </div>

        <div class="van-cell" v-show="couponNumShow">
          <span class="label">优惠券</span>
          <span  class="value price" @click="checkCoupon" id="couponsNum">
           {{couponNum}} 张可用
        </span>
        </div>

        <div class="van-cell">
          <span class="label">支付总金额</span>
          <span  class="value price">
            ￥{{total}}
        </span>
        </div>
      </div>
      <van-cell-group>
        <van-field
          v-model="note"
          label="备注"
          left-icon="smile-o"
          right-icon="warning-o"
          placeholder="备注信息"
        />
      </van-cell-group>
      <div class="notice">
        <span>提醒：实付满49元包邮（新疆，西藏地区除外）</span>
      </div>

    </div>

    <van-submit-bar  :price="total*100" decimal-length="2" button-text="提交订单" @submit="onSubmit">
      <span>共 {{quantity}} 件</span>
    </van-submit-bar>
  </div>
  <div class="coupons" v-show="showCoupons">
    <span class="title">可使用优惠券</span>
    <ul v-for="(item, index) in coupons" :key="index">
      <li class="box">
        <div class="card">
          <div class="price">￥{{item.value}}</div>
          <div class="content">
            <span class="name">{{item.name}}</span>
            <span class="time">有效期至 {{item.endDesc}} </span>
          </div>
          <div :class="item.isCheck==true ? 'checked':'noCheck'" @click="check(item)"></div>
        </div>
        <div class="intro">{{item.description}}</div>
      </li>
    </ul>
    <div class="submit">
      <span class="cancel" @click="cancel">取消</span>
      <span class="confirm" @click="confirm">确认</span>
    </div>

  </div>
</template>

<script>
import { ref,onActivated} from 'vue';
import { showToast  } from "vant";
import { useRouter } from 'vue-router';
import { orderInfo,wxPay,useCoupons,checkStock} from '@/service/order'
import { getSignature } from '@/service/course'
import wx from "weixin-js-sdk"
export default {
  setup() {
    const router = useRouter();
    const quantity=ref(0);
    const enabled = ref(true); // 开启配送
    const delivery = ref({}); // 配送信息
    const deliveryDesc=ref('');
    const goodsList=ref([]);
    const goods=ref('');
    const total=ref(0);
    const origPrice=ref(0);
    const dedShow=ref(false);
    const dedPrice=ref(0);
    const vip=ref(0);
    const orderParam=ref({});
    const storage= localStorage.getItem("token-hyxt");
    const token=JSON.parse(storage);
    const wechat=ref({});
    const note=ref('');
    const coupons=ref([]);
    const couponNum=ref(0);
    const couponNumShow=ref(false);
    const packIds=ref([]);
    const couponsParam=ref({});
    const showCoupons=ref(false);
    const loadData = async () => {
      couponsParam.value.userId=token.userId;
      couponsParam.value.arrayParam=packIds.value;
      console.log("couponsParam")
      console.log(JSON.stringify(couponsParam.value))
      getCoupons();
    }

    const checkCoupon = () => {
      if(enabled.value && JSON.stringify(delivery.value) == '{}'){
        showToast('请选取配送信息');
      }else{
        if(coupons.value.length>0){
          showCoupons.value=true;
        }
      }
    }

    const check = (item) =>{
      if(item.isCheck){
        item.isCheck=false
      }else{
        item.isCheck=true
      }
    }
    const getCoupons=()=>{
      useCoupons({userId:couponsParam.value.userId,arrayParam:couponsParam.value.arrayParam}).then(res=>{
        coupons.value = res.data.data;
        if(coupons.value.length>0){
          dedShow.value=true;
          couponNum.value=coupons.value.length;
          couponNumShow.value=true;
        }
      })
    }
    const cancel=()=>{
      showCoupons.value=false;
      for(var i=0;i<coupons.value.length;i++){
        coupons.value[i].isCheck=false;
      }
      total.value=origPrice.value;
      dedPrice.value=0;
      // dedShow.value=false;
    }
    const confirm=()=>{
      total.value=origPrice.value;
      dedPrice.value=0;
      for(var i=0;i<coupons.value.length;i++){
        if(coupons.value[i].isCheck){
          total.value=total.value-coupons.value[i].value;
          dedPrice.value=Number(dedPrice.value) + Number(coupons.value[i].value);
          couponNum.value=couponNum.value-1;
        }
      }
      if(couponNum.value<1){
        couponNumShow.value=false;
      }
      if(total.value<=0){
        showToast("优惠券抵扣总金额不能大于订单总金额！")
      }else{
        showCoupons.value=false;
      }

      getDelivery();


    }

    onActivated(()=>{
      if (isIOS()) {
        console.log("这是iOS设备");
      } else{
        //刷新当前页面
        console.log("这是Android设备");
        const url= location.href.split('#')[0];
        wechatConfig(url);
      }

      goods.value=router.currentRoute.value.query.goods;//商品
      orderInfo({goods:goods.value,userId:token.userId}).then(res =>{
        goodsList.value=res.data.data.list;
        total.value=res.data.data.total;
        origPrice.value=res.data.data.total;
        quantity.value=res.data.data.quantity;
        vip.value=res.data.data.vip;
        enabled.value=res.data.data.enabled;
        packIds.value.length=0;
        for(var i=0;i<goodsList.value.length;i++){
          packIds.value.push(goodsList.value[i].packId);
        }
        dedPrice.value=0;
        loadData();
        getDelivery();
      })
    })
    const isIOS=()=>{
      return /(iPhone|iPad|iPod)/i.test(navigator.userAgent);
    }
    // const isAndroid=()=>{
    //   return /(Android)/i.test(navigator.userAgent);
    // }
    const wechatConfig=(url)=>{
      getSignature({url:url}).then(res=>{
        wechat.value=res.data;
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: wechat.value.appId, // 必填，公众号的唯一标识
          timestamp: wechat.value.timestamp, // 必填，生成签名的时间戳
          nonceStr: wechat.value.noncestr, // 必填，生成签名的随机串
          signature: wechat.value.signature, // 必填，签名，见附录1
          jsApiList: ['checkJsApi', 'chooseWXPay',"openAddress"]
          // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        });
        wx.error(function (res) {
          alert(JSON.stringify(res));
        })
      })
    }
    // 提交订单
    const onSubmit = () => {
      if(enabled.value && JSON.stringify(delivery.value) == '{}') {
        //
        showToast('请选取配送信息');
        return;
      }
          //购买
        orderParam.value.userId=token.userId;
        orderParam.value.conName=delivery.value.conName;
        orderParam.value.conMobile=delivery.value.conMobile;
        orderParam.value.conAddress=delivery.value.conAddress;
        orderParam.value.goods=goods.value;
        orderParam.value.coupons=JSON.stringify(coupons.value);
      checkStock({goods:orderParam.value.goods}).then(result=>{
        if(result.data.status==1){
          //库存足够
          wx.ready(function () {
            wxPay({openId:token.openId,userId:orderParam.value.userId,goods:orderParam.value.goods,conName:orderParam.value.conName,conMobile:orderParam.value.conMobile,conAddress:orderParam.value.conAddress,note:note.value,coupons:orderParam.value.coupons}).then(res=>{
              const result=res.data.data;
              wx.ready(function () {
                wx.chooseWXPay({
                  appId: result.appId,
                  timestamp: result.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                  nonceStr: result.nonceStr, // 支付签名随机串，不长于 32 位
                  package: result.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                  signType: result.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                  paySign: result.paySign, // 支付签名
                  success:function () {
                    //支付成功后的回调
                    showToast('支付成功')
                    router.push({path:"/pay/store"});
                  },
                  fail:function () {
                    showToast('支付失败')
                  }
                });
                wx.error(function (res) {
                  alert(JSON.stringify(res));
                })
              });
              wx.error(function (res) {
                alert(JSON.stringify(res));
              })

            })
          })
        }else{
          showToast(result.data.msg)
        }

      })
    }

    const getDelivery=()=>{
      if(delivery.value.conAddress){
        if(delivery.value.conAddress.includes("新疆") || delivery.value.conAddress.includes("西藏")){
          //邮费20
          if(total.value>=49){
            deliveryDesc.value='10元'
            total.value=Number(total.value)+10;
          }else{
            deliveryDesc.value='20元'
            total.value=Number(total.value)+20;
          }

        }else{
          if(vip.value==0){
            //非全站
            if(total.value>=49){
              deliveryDesc.value='包邮'
            }else{
              deliveryDesc.value='10元'
              total.value=Number(total.value)+10;
            }
          }else{
            //全站 29元包邮
            if(total.value>=29){
              deliveryDesc.value='包邮'
            }else{
              deliveryDesc.value='10元'
              total.value=Number(total.value)+10;
            }

          }

        }
      }
    }


    const getWxAddr = ()=>{
      wx.ready(function () {
        wx.openAddress({
          success: function (res) {
            delivery.value.conAddress=res.provinceName + res.cityName + res.countryName +res.detailInfo;
            delivery.value.conName=res.userName;
            delivery.value.conMobile=res.telNumber;
            getDelivery();

          },
        });
      })
      // delivery.value.conAddress="河南省郑州市金水区国际企业中心A座";
      // delivery.value.conName="李鹏飞";
      // delivery.value.conMobile="18739923673";
      getDelivery();

    }



    return {
      quantity,
      note,
      total,
      goodsList,
      enabled,
      delivery,
      onSubmit,
      deliveryDesc,
      getWxAddr,
      coupons,
      checkCoupon,
      cancel,
      showCoupons,
      check,
      confirm,
      origPrice,
      dedShow,
      dedPrice,
      couponNumShow,
      couponNum
    }
  }
}
</script>

<style lang="scss">
  .order-form {
    .delivery {
      .left-icon, .right-icon {
        height: var(--van-cell-line-height);
        font-size: var(--van-cell-icon-size);
        line-height: var(--van-cell-line-height);
      }
      .left-icon {
        margin-right: var(--van-padding-base);
        // color: var(--van-contact-card-add-icon-color);
        // font-size: var(--van-contact-card-add-icon-size);
      }
      .right-icon {
        margin-left: var(--van-padding-base);
        color: var(--van-cell-right-icon-color);
      }
      .add-contacts {
        color: #666;
      }
      .address {
        font-size: 24px;
        line-height: 24px;
        color: var(--van-cell-right-icon-color);
      }
    }
    .goods {
      .content {
        padding: 16px 0;
        background-color: #fff;
        .van-card {
          background-color: #fff;
          .van-card__price {
            color: red;
          }
        }
        .van-cell {
          .label {
            flex: 1;
            color: var(--van-cell-value-color);
          }
          .value {
            flex: 1;
            text-align: right;
            color: red;
          }
          >.price {
            font-size: var(--van-card-price-integer-font-size);
            font-weight: var(--van-font-weight-bold);
            font-family: var(--van-card-price-font-family);
            color: red;
          }
          >.disabled {
            color: var(--van-cell-value-color);
          }
        }
      }
      .notice{
        margin-top: 10px;
        color: red;
        font-size: 24px;
      }
    }
    .notes {
      margin-top: 30px;
      .van-cell__title {
        flex: inherit;
        color: var(--van-cell-value-color);
      }
      .van-cell__value {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .header {
        line-height: 40px;
        font-weight: 500;
        padding: var(--van-cell-vertical-padding) var(--van-cell-horizontal-padding) 0px;
      }
      textarea {
        background-color: #f7f8fa;
      }
    }
    // .delivery {
    //   position: relative;
    //   background-color: #fff;
    //   // padding: var(--van-contact-card-padding);
    //   .van-cell {
    //     display: flex;
    //     align-items: center;
    //     .add-icon {
    //       color: var(--van-contact-card-add-icon-color);
    //       font-size: var(--van-contact-card-add-icon-size);
    //     }
    //   }

    //   &::before {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     left: 0;
    //     height: 2px;
    //     background: repeating-linear-gradient(-45deg,var(--van-warning-color) 0,var(--van-warning-color) 20%,transparent 0,transparent 25%,var(--van-primary-color) 0,var(--van-primary-color) 45%,transparent 0,transparent 50%);
    //     background-size: 80px;
    //     content: "";
    //   }
    // }

  }
  .coupons{
    box-sizing: border-box;
    position: fixed;
    width: 750px;
    height: 1557px;
    background: #ECECEC;
    z-index: 100;
    top: 10px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 40px;
    .title{
      width: 136px;
      height: 26px;
      font-family: PingFang SC;
      font-weight: 500;
      font-size: 28px;
      color: #666666;
      line-height: 50px;
    }
    .box{
      width: 686px;
      height: 212px;
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #ECECEC;

      .card{
        width: 686px;
        height: 158px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .price{
          display: block;
          width: 154px;
          height: 59px;
          font-family: Roboto;
          font-weight: 500;
          font-size: 50px;
          color: #F8483F;
          line-height: 50px;
          margin-left: 40px;
        }

        .content{
          width: 243px;
          height: 160px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .name{
            display: block;
            height: 30px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 32px;
            color: #333333;
            line-height: 50px;
          }
          .time{
            display: block;
            height: 23px;
            font-family: PingFang SC;
            font-weight: 500;
            font-size: 24px;
            color: #999999;
            line-height: 50px;
          }

        }
        .noCheck{
          width: 30px;
          height: 30px;
          background: #FFFFFF;
          border-radius: 50%;
          border: 1px solid #CCCCCC;
          margin-right: 30px;
        }
        .checked{
          width: 30px;
          height: 30px;
          background: #F8483F;
          border-radius: 50%;
          border: 1px solid #CCCCCC;
          margin-right: 30px;
        }

      }
      .intro{
        box-sizing: border-box;
        width: 686px;
        height: 54px;
        background: #FFF0F0;
        border-radius: 10px;
        padding-left: 39px;

        font-family: PingFang SC;
        font-weight: 500;
        font-size: 24px;
        color: #666666;
        line-height: 54px;


      }
    }
    .submit{
      position: fixed;
      width:686px;
      height:92px;
      bottom: 200px;
      display: flex;
      justify-content: space-evenly;

      .confirm{
        display: inline-block;
        width: 260px;
        height: 76px;
        background: linear-gradient(269deg, #FF7132, #F8483F);
        border-radius: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
      }
      .cancel{
        display: inline-block;
        width: 260px;
        height: 76px;
        background: linear-gradient(269deg, #FF7132, #F8483F);
        border-radius: 38px;
        font-family: PingFang SC;
        font-weight: 500;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 76px;
        text-align: center;
      }
      
    }






  }
</style>
